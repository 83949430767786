<template>
  <b-card
    no-body
    class="card__ p-1"
  >
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <div class="d-flex align-items-center">
          <div class="title_ mr-1">
            Sede
          </div>
          <v-select
            v-model="selectedBrand"
            label="name"
            :options="records"
            class="select"
            placeholder="Seleccione sede"
            @input="loadServiceByBrand"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </div>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <div class="d-flex align-items-center">
          <div class="title_ mr-1">
            Servicio
          </div>
          <v-select
            v-model="selectedService"
            label="text"
            :options="services"
            class="select"
            placeholder="Seleccione servicio"
            @input="loadAreaByService"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </div>
      </b-col>
      <b-col md="6">
        <div class="d-flex align-items-center">
          <div class="title_ mr-1">
            Area
          </div>
          <v-select
            v-model="selectedArea"
            label="text"
            :options="areas"
            class="select"
            placeholder="Seleccione area"
            @input="loadLocationPatient"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
 BCard, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
  inject: ['listBedRepository'],
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
  },
  mixins: [CRUDMixin],
	props: {
		date: {
			type: String,
			default: null,
		},
		typeFood: {
			type: String,
			default: null,
		},
	},
  data() {
    return {
      selectedBrand: null,
      selectedService: null,
      selectedArea: null,
      records: [],
      services: [],
      areas: [],
      locationPatients: [],
      repository: 'listBedRepository',
      isBusy: false,
    }
  },
  async mounted() {
    const me = this
    await me.handleLoadData2()
  },
  methods: {
    async loadServiceByBrand() {
      const me = this
      try {
        me.resetSelect('1')
        const res = await me.listBedRepository.getAllServicesByBrand(me.selectedBrand.id)
        const { data } = res
        if (data.length > 0) {
          me.isDisabledService = false
          data.forEach(el => {
            me.services.push({ id: el.id, text: el.name })
          })
        } else {
          me.isDisabledService = true
          me.$bvToast.toast('No existen servicios registrado para la sede seleccionada', {
            title: 'Sin servicios',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true,
            appendToast: true,
          })
          me.$emit('loadLocationPatients', me.locationPatients)
        }
      } catch (error) {
        // console.log(error)
        me.$emit('loadLocationPatients', me.locationPatients)
      }
    },
    async loadAreaByService() {
      const me = this
      try {
        me.resetSelect('2')
        const res = await me.listBedRepository.getAllAreaByService(me.selectedService.id)
        const { data } = res
        data.forEach(el => {
          me.areas.push({ id: el.id, text: el.name })
        })
        me.isDisabledArea = false
      } catch (error) {
        me.isDisabledArea = true
        me.$bvToast.toast('No existen areas registrado para el servicio seleccionado', {
          title: 'Sin areas',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
        me.$emit('loadLocationPatients', me.locationPatients)
        // console.log(error)
      }
    },
    async loadLocationPatient(typeFood = null, date = null) {
      const me = this
      me.locationPatients = []
      try {
				// if (typeFood && date) {
				// 	const res = await me.listBedRepository.getAllLocationPatient(me.selectedArea.id, typeFood, date)
				// } else {
				// 	const res = await me.listBedRepository.getAllLocationPatient(me.selectedArea.id, me.typeFood, me.date)
				// }

				const res = (typeFood && date) ? await me.listBedRepository.getAllLocationPatient(me.selectedArea.id, typeFood, date) : await me.listBedRepository.getAllLocationPatient(me.selectedArea.id, me.typeFood, me.date)

				res.forEach(el => {
          el.list.forEach(elem => {
            me.locationPatients.push({ ...elem, area_id: el.area_id })
          })
        })
      } catch (error) {
        me.locationPatients = []
        me.$bvToast.toast('No existen camas registradas para el subarea seleccionada', {
          title: 'Sin camas',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
      } finally {
        const data = {
          serviceId: me.selectedService.id,
          areaId: me.selectedArea.id,
          area: me.selectedArea,
          brand: me.selectedBrand,
          service: me.selectedService,
        }
        me.$emit('loadLocationPatients', me.locationPatients, data)
      }
    },
    resetSelect(type) {
      const me = this
      if (type == '1') {
        me.services = []
        me.areas = []
        me.locationPatients = []
        me.selectedService = null
        me.selectedArea = null
      } else if (type == '2') {
        me.areas = []
        me.locationPatients = []
        me.selectedArea = null
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .select {
    width: 100%;
  }
  .title_ {
    font-size: 14px;
    color: #636363;
    font-weight: bold;
  }
</style>
